import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { charte } from "../components/charte"
import labo15 from "../images/labo15.png"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Image from "react-bootstrap/Image"
import Col from "react-bootstrap/Col"
import CDF from "../images/CDF.png"
import CIRB from "../images/logo-cirb.png"

const About = () => (
  <Layout page={"About"}>
    <h1 align="center" style={{color: charte.primary}}>About...</h1>
    <p/>
    <p/>
    <p align="justify">

      Our "environment-ecology-climate" collective was set up following the CIRB days in June 2019.
      We noted that in its "eco-responsible" aspects, research activity as we practice it today could largely and easily be improved.
      All over the world, research centres, some of them extremely prestigious, are working very seriously on this problem.
      In France, the Labos 1point5 collective proposes to federate initiatives at the national level.
      Greencom CIRB would like to welcome you in large numbers, regardless of your current footprint and your knowledge of the climate.
      The purpose of this collective is to provide all CIRB volunteer teams with tools to measure their impact on the environment and in particular on climate change, as well as solutions to reduce it, in an optimal way, without this having a negative effect on our research time and efficiency.
      The actions planned for the near future are:
      <p/>
      <ol>
        <li>Conduct a poll modelled on that of Greencom IBENS on the environmental crisis and our role as workers in the scientific field/scientific institutes. Organize a "CIRB Green meeting" to discuss these issues.
        </li>
        <li>Offer a shared tool to measure our environmental impact on 3 main areas: energy consumption (freezers, hoods, computers and Internet), waste production (experimental equipment, furniture), CO2 emissions (air transport linked to missions, commuting, conference meal menus).
        </li>
        <li>Organize the "Green Challenge CIRB" on the model of what has been done at Harvard or Cambridge.
          The idea is that teams volunteering to participate in this game use the shared tool to calculate their current annual footprint, and then each team uses its collective inventiveness to reduce its footprint in 2020. The goal of the game is to have the lowest footprint among the participating teams and/or the largest reduction in that footprint at the end of the game.
          The modalities of course remain largely to be specified and for that we need a small core of volunteers from each team.
        </li>
      </ol>
      By doing all this, we hope to enjoy ourselves, share our experience with other laboratories and contribute to a better life for future generations.

      If you are interested, contact us!
    </p>

    <h1 align="center" style={{color: charte.primary}}>They support us</h1>
    <p/>
    <p/>

    <Container>
      <Row>
        <Col align="center">
          <Image src={labo15} Thumbnail fluid/>
        </Col>
        <Col align="center">
          <Image src={CDF} Thumbnail fluid />
        </Col>
          <Col align="center">
            <Image src={CIRB} Thumbnail fluid />
        </Col>
      </Row>
    </Container>

  </Layout>
)

export default About